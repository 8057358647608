<template>
  <div class="disbursement-invoice-list">
    <div class="ss-table-container--overflow">
      <table class="ss-table">
        <thead>
          <tr>
            <th v-for="(text, index) in tableHead" :key="index">{{ text }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="9">
              <bg-skeleton width="100%" />
            </td>
          </tr>
          <template v-else>
            <template v-if="invoices.length">
              <tr
                v-for="(invoice, index) in invoices"
                :key="`${index}-${invoice.invoice_id}`"
              >
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <div class="mb-8">
                    <component
                      :is="invoice.hasTooltip ? `BgTooltip` : 'div'"
                      :closable="false"
                      trigger="hover"
                    >
                      <bg-link
                        v-if="invoice.mamipay_invoice_url"
                        :href="invoice.mamipay_invoice_url"
                        target="_blank"
                        rel="noopener"
                      >
                        {{ invoice.invoice_number }}
                      </bg-link>
                      <span v-else class="td-u">
                        {{ invoice.invoice_number }}
                      </span>

                      <div
                        v-if="invoice.hasTooltip"
                        slot="tooltip-content"
                        class="flex justify-space-between align-center"
                        style="min-width: 260px"
                      >
                        <bg-text size="label-4">
                          {{ invoice.tooltipLabel }}
                        </bg-text>
                        <bg-text size="label-4">
                          {{ invoice.tooltipDate }}
                        </bg-text>
                      </div>
                    </component>
                  </div>
                  <bg-label-rainbow
                    v-if="invoice.disbursed_invoice_type"
                    :color="
                      invoice.disbursed_invoice_type === 'billing'
                        ? 'white'
                        : 'blue'
                    "
                  >
                    <span class="tt-capitalize">{{
                      invoice.disbursed_invoice_type
                    }}</span>
                  </bg-label-rainbow>
                  <bg-label-rainbow
                    v-if="invoice.disbursement_progress"
                    color="yellow"
                  >
                    Disburse {{ invoice.disbursement_progress }}
                  </bg-label-rainbow>
                </td>
                <td>
                  <p>{{ invoice.tenant_name }}</p>
                  <p>
                    <span class="text-muted">Kamar</span>
                    {{ invoice.tenant_room_number }}
                  </p>
                  <p>
                    <span class="text-muted">Tipe</span> {{ invoice.room_type }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ formatContractDate(invoice.period_contract_start) }} -
                    <br />
                    {{ formatContractDate(invoice.period_contract_end) }}
                  </p>
                  <p v-if="invoice.description_duration || invoice.rent_type">
                    ({{
                      invoice.invoice_type === 'manual_invoice'
                        ? invoice.description_duration
                        : rentTypeFormat(invoice.rent_type)
                    }})
                  </p>
                </td>
                <td>
                  {{ rupiah(invoice.invoice_amount) }}
                </td>
                <td>{{ rupiah(invoice.static_price) }}</td>
                <td>{{ rupiah(invoice.actual_listing_price) }}</td>
                <td>
                  {{ rupiah(invoice.disbursed_amount) }}
                </td>
                <td>
                  <table-action-menu
                    v-if="accessEditTransactionDisbursement"
                  >
                    <bg-list-item
                      :clickable="isUnapproved && invoice.invoice_type !== 'manual_invoice'"
                      :class="{ disabled: !isUnapproved || invoice.invoice_type === 'manual_invoice'}"
                      @click="handleEditTransaction(invoice)"
                    >
                      Ubah Transaksi
                    </bg-list-item>
                    <bg-list-item
                      v-if="invoice.invoice_type !== 'manual_invoice'"
                      :clickable="isUnapproved"
                      :class="{ disabled: !isUnapproved }"
                      @click="handleGoToAdditionalCost(invoice)"
                    >
                      Detail Biaya Tambahan
                    </bg-list-item>
                    <bg-list-item
                      :clickable="isUnapproved && invoice.invoice_type !== 'manual_invoice'"
                      :class="{ disabled: !isUnapproved || invoice.invoice_type === 'manual_invoice'}"
                      @click="handleDeleteTransaksi(invoice)"
                    >
                      Hapus Transaksi
                    </bg-list-item>
                  </table-action-menu>
                </td>
              </tr>
              <tr>
                <td colspan="7" class="ta-r fw-b">Total Penjualan Kamar</td>
                <td colspan="2" class="ta-l fw-b">
                  {{ totalIncome }}
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="9" class="ta-c va-m">
                Tidak ada data yang ditampilkan.
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  BgLabelRainbow,
  BgSkeleton,
  BgText,
  BgListItem,
  BgLink,
  BgTooltip,
} from 'bangul-vue';
import TableActionMenu from '@admin_molecules/TableActionMenu';
import periode from 'Utils/dictionaries/periode';
import { rupiahFormatter, dateFormatterToDisplay } from 'Utils/formatter';

export default {
  name: 'DisbursementInvoiceList',

  components: {
    BgLabelRainbow,
    BgSkeleton,
    BgText,
    BgListItem,
    TableActionMenu,
    BgLink,
    BgTooltip,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isUnapproved: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      invoices: [],
    };
  },

  computed: {
    accessEditTransactionDisbursement() {
      return this.$store.getters.xCheckUserPermission(
        'edit-transaction-disbursement'
      );
    },
    tableHead() {
      return [
        'No',
        'Invoice',
        'Detail Penyewa',
        'Periode Invoice',
        'Pembayaran di Invoice',
        'Harga Statis',
        'Harga Sewa kamar',
        'Jumlah Ditransfer',
        'Action',
      ];
    },
    totalIncome() {
      const total = this.invoices.reduce(
        (prev, next) => prev + Number(next.disbursed_amount),
        0
      );
      return this.rupiah(total);
    },
  },

  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.invoices = this.mappingData(value);
      },
    },
  },

  methods: {
    formatContractDate(format) {
      if (!format) {
        return '';
      }

      const date = new Date(format);
      const temp = dayjs(date).format('DD/MM/YYYY');
      return `${temp}`;
    },

    rupiah(value) {
      /* eslint-disable-next-line no-restricted-globals */
      if (value === '-' || !isFinite(value) || value === null) {
        return '-';
      }

      return rupiahFormatter(value);
    },

    rentTypeFormat(value) {
      const translated = periode(value);

      return translated.id_text ? `Per ${translated.id_text}` : '-';
    },

    handleEditTransaction(payload) {
      if (this.isUnapproved || payload.invoice_type !== 'manual_type') {
        this.$emit('emit-edit-transaction', payload);
      }
    },

    handleGoToAdditionalCost(payload) {
      this.$emit('emit-go-to-additional-cost', payload);
    },

    handleDeleteTransaksi(payload) {
      this.$emit('emit-handle-delete-transaksi', payload);
    },

    mappingData(invoices) {
      return invoices.map(invoice => {
        const tooltipData = (() => {
          switch (invoice.disbursed_invoice_type) {
            case 'billing':
              return {
                label: 'Tanggal Terbayar',
                date: invoice.paid_date,
              };

            case 'booking':
              return {
                label: 'Tanggal Check-in',
                date: invoice.check_in_date,
              };

            default:
              return {};
          }
        })();

        return {
          ...invoice,
          hasTooltip:
            invoice.invoice_type === 'mamikos' &&
            !!invoice.disbursed_invoice_type,
          tooltipLabel: tooltipData.label || '-',
          tooltipDate: tooltipData.date
            ? dateFormatterToDisplay(tooltipData.date, 'DD/MM/YYYY')
            : '-',
        };
      });
    },
  },
};
</script>

<style src="./DisbursementInvoiceList.scss" lang="scss" scoped />